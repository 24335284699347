<template>
  <b-card-code title="" no-body>
    <b-card-body
      ><h3>Total Schedule : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="3" xl="3" class="mb-1">
          <!-- basic -->
          <b-form-group label="Mentor Email" label-for="basicInput">
            <b-form-input
              id="basicInput"
               placeholder="mentor@email.com"
              v-model="search_mentor"
            />
          </b-form-group>
        </b-col>
         <b-col md="3" xl="3" class="mb-1">
          <!-- basic -->
          <b-form-group label="Member Email" label-for="basicInput">
            <b-form-input
              id="basicInput"
               placeholder="member@email.com"
              v-model="search_member"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" xl="3" class="mb-1">
          <!-- basic -->
          <b-form-group label="Status" label-for="status">
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              multiple
              :options="statusList"
            />
          </b-form-group>
        </b-col>
       
        

        <b-col lg="3" md="3" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

    
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(mentor)="data">
        <p>{{setMemberInfo(data.value)}}</p>
        
      </template>
      <template #cell(member)="data">
        <p>{{setMemberInfo(data.value)}}</p>
        
      </template>
      <template #cell(date)="data">
        <p>{{data.value}}</p>
        
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showModalNotificationRecord(data.item)">
            <feather-icon icon="BellIcon" size="16" />
            <span class="align-middle ml-50">Notification Record</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalInputGmeet(data.item)">
            <feather-icon icon="MonitorIcon" size="16" />
            <span class="align-middle ml-50">Input Gmeet Link</span>
          </b-dropdown-item>
          <b-dropdown-item @click="finishOrder(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Finish</span>
          </b-dropdown-item>
        
        </b-dropdown>
      </template>
      <template #cell(created_at)="data">
        <p>{{convertISODateTime(data.value)}}</p>
        
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
  

   
    <b-modal id="modal-input-gmeet" hide-footer>
      <template #modal-title> Input Gmeet Link </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
          <b-form-group label="Gmeet Link" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="https://meet.google.com/"
              v-model="link_gmeet"
            />
          </b-form-group>
      

      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedType == null"
        @click="submitGmeetLink()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>

    <b-modal id="modal-notification-record" hide-footer>
      <template #modal-title> Notification Record </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
         <ul>
          <li v-for="(notification,index) in this.notification_record" :key="index">{{index + " => "+notification}}</li>
         </ul>
      
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 50,
      modes: ['multi', 'single', 'range'],
      fields: ['mentor','member',{ key: 'topic.name', label: 'Topic' },{ key: 'schedule.date', label: 'Date' }, { key: 'schedule.start', label: 'Start' }, { key: 'schedule.end', label: 'End' }, 'status', 'action', 'created_at'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Registered', 1: 'Accepted', 2: "Canceled", 3: 'Finished'
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-warning', 3: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],

      selectedJurusans: [],
      selectedFakultas: [],
      fakultass: [],
      jurusans: [],
      search_mentor: null,
      search_member: null,
      email: null,
      link_gmeet: null,
      detailSchedule: {},
      typeList: [
        { id: 2, name: "Can't find" },
        { id: 3, name: 'Input wrong data' }
      ],
      selectedType: { id: 2, name: "Can't find" },
      statusList: [
        { id: 0, name: 'Registered' },
        { id: 1, name: 'Accepted' },
        { id: 2, name: "Canceled" },
        { id: 3, name: "Finished" }
      ],

      selectedStatus: [],
      notification_record:[]

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getOrder()
      }
      else {
        this.currentPage = 1
      }

    },
    async getOrder() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var statusIds = this.selectedStatus.length > 0 ? this.selectedStatus.map(function (item) { return item['id'] }) : null;

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search_mentor: this.search_mentor,
          search_member: this.search_member,
          status: statusIds ? statusIds : null

        }
        const { data } = await API.mentorship.order(form)
        console.log(data)
        this.data_table = data.data
        this.items = data.data.data
        this.rows = data.data.total

        this.handleMsgSuccess('Berhasil tampilkan data schedule')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async submitGmeetLink() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

       

        const form = {
          link_gmeet: this.link_gmeet,
          schedule_id:this.detailSchedule.schedule.id
        }
        const { data } = await API.mentorship.gmeet(form)
    
        this.link_gmeet = null
       

        this.handleMsgSuccess('Berhasil input gmeet')
        this.$bvModal.hide("modal-input-gmeet")
        this.getOrder()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async finishOrder(item) {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          order_id: item.id,
          status:3
        }
        const { data } = await API.mentorship.status(form)
        this.handleMsgSuccess('Berhasil rubah status')
        this.getOrder()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    
    showModalInputGmeet(item) {
      this.detailSchedule = item
      this.link_gmeet = this.detailSchedule.schedule.link_gmeet
      console.log(this.detailSchedule)
      this.$bvModal.show("modal-input-gmeet")

    },

    showModalNotificationRecord(item) {
      this.detailSchedule = item
      this.notification_record = JSON.parse(this.detailSchedule.notification_record)
      console.log(this.notification_record)
      this.$bvModal.show("modal-notification-record")

    },
    convertISODateTime(isoStr1){
      // ✅ Create a Date object (setting the date to local time)
      return moment(isoStr1).toString();

    },
    setMemberInfo(member) {
      return member.nama_panjang +'-'+member.member.email+' - ' + member.no_telp
    }



  },
  mounted() {
    this.getOrder()
  },
  watch: {
    currentPage() {
      this.getOrder()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
